import { getFirestore, collection, query, limit, startAfter, SnapshotOptions, QueryDocumentSnapshot, getDocs, Query, orderBy, startAt, where, QueryConstraint } from "firebase/firestore"
import { Blog } from "../entity/Blog"
import { FirebaseApp } from "../firebase"

const db = getFirestore(FirebaseApp)
const COLLECTION_NAME = "blog"
const blogRef = collection(db, COLLECTION_NAME)

const blogConverter = {
    "toFirestore": (blog: Blog) => {
        return {
            id: blog.id,
            title: blog.title,
            date: blog.date,
            year_month: blog.yearMonth,
            category: blog.category,
            html: blog.html,
            ext_html: blog.extHtml
        }
    },
    "fromFirestore": (snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Blog => {
        const data = snapshot.data(options)
        return {
            id: data.id,
            title: data.title,
            date: data.date.toDate(),
            yearMonth: data.year_month,
            category: data.category,
            html: data.html,
            extHtml: data.ext_html
        }
    }
}

const executeQuery = <T>(query: Query<T>) => getDocs(query).then(snapshot => snapshot.docs.map((doc) => doc.data()))

type QueryParams = {
    size?: number | null,
    filterDate?: string | null,
    category?: string | null,
    lastBlog?: Blog | null,
    startBlog?: Blog | null
}

const queryBuilder = (params: QueryParams) => {
    const queryParams = [] as QueryConstraint[]
    if (params.filterDate) {
        queryParams.push(where("year_month", "==", params.filterDate))
    }
    if (params.category) {
        queryParams.push(where("category", "array-contains", params.category))
    }
    queryParams.push(orderBy("date", "desc"))
    if (params.lastBlog) {
        queryParams.push(startAfter(params.lastBlog.date))
    }
    if (params.startBlog) {
        queryParams.push(startAt(params.startBlog.date))
    }
    if (params.size) {
        queryParams.push(limit(params.size))
    }
    return query(blogRef, ...queryParams).withConverter(blogConverter)
}

export const getAllBlogs = () => executeQuery(queryBuilder({}))

export const getBlogs = (
    size: number | null,
    filterDate: string | null,
    category: string | null
) => executeQuery(queryBuilder({ size: size, filterDate: filterDate, category: category }))

export const getAfterBlogs = (
    size: number,
    lastBlog: Blog,
    filterDate: string | null,
    category: string | null
) => executeQuery(queryBuilder({ size: size, filterDate: filterDate, category: category, lastBlog: lastBlog }))

export const getStartAtBlogs = (
    size: number,
    startBlog: Blog,
    filterDate: string | null,
    category: string | null
) => executeQuery(queryBuilder({ size: size, filterDate: filterDate, category: category, startBlog: startBlog }))