import React from "react";
import { Blog } from '../entity/Blog';
import { Badge } from "react-bootstrap";

export type EntryProps = {
    blog: Blog
}

const Entry: React.FC<EntryProps> = (props: EntryProps) => {
    return (
        <div className="entry mt-3" >
            <div className="blogHeader mb-1">
                <div className="blogTitle">
                    <h2>{props.blog.title}</h2>
                </div>
                {props.blog.category.map(ctg => <Badge key={ctg} variant="light">{ctg}</Badge>)}
                <div className="blogDate">
                    <p>{props.blog.date.toLocaleDateString()}</p>
                </div>
            </div>

            <div className="blogBody" dangerouslySetInnerHTML={{ __html: props.blog.html }} />
        </div>
    )
}

export default Entry