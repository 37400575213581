// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDnw9YvU_yaFtUvwla4XSgJ1sRLPUDTRzo",
    authDomain: "tada-architect.firebaseapp.com",
    projectId: "tada-architect",
    storageBucket: "tada-architect.appspot.com",
    messagingSenderId: "1031499964805",
    appId: "1:1031499964805:web:773d5eb6ad6714172764ec",
    measurementId: "G-XEWM0P6E1N"
};

// Initialize Firebase
export const FirebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);