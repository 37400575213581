import React, { Dispatch, SetStateAction } from "react";
import { Navbar, Nav, Form, InputGroup } from "react-bootstrap";

export type HeaderProps = {
    yearMonths: string[],
    setFilterYearMonth: Dispatch<SetStateAction<string | null>>,
    categories: string[],
    setFilterCategory: Dispatch<SetStateAction<string | null>>,
}

const Header = (props: HeaderProps) => {

    const nullDate = "----/--"
    const onChangeFilterDate = (yearMonth: string) => {
        props.setFilterYearMonth(yearMonth === nullDate ? null : yearMonth)
    }
    const nullCategory = "--------"
    const onChangeFilterCategory = (category: string) => {
        props.setFilterCategory(category === nullCategory ? null : category)
    }

    return (
        <Navbar bg="light" variant="light" expand="lg">
            <Navbar.Brand href="#home">知的・美的なおしゃれ生活</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Form inline>
                <InputGroup className="ml-2 mr-2 mt-1 mb-1" >
                    <InputGroup.Prepend>
                        <InputGroup.Text>年月</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" onChange={(e) => onChangeFilterDate(e.target.value)}>
                        <option>{nullDate}</option>
                        {props.yearMonths.map(yearMonth => <option key={yearMonth}>{yearMonth}</option>)}
                    </Form.Control>
                </InputGroup>
                <InputGroup className="ml-2 mr-2 mt-1 mb-1" >
                    <InputGroup.Prepend>
                        <InputGroup.Text>カテゴリ</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" onChange={(e) => onChangeFilterCategory(e.target.value)}>
                        <option>{nullCategory}</option>
                        {props.categories.map(category => <option key={category}>{category}</option>)}
                    </Form.Control>
                </InputGroup>
            </Form>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="https://www.arttada.com/">多田建築設計事務所</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header;