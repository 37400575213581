import React from 'react';
import { Spinner, Modal } from 'react-bootstrap';

export type LoadingProps = {
    show: boolean
}

const Loading = (props: LoadingProps) => {
    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body style={{ textAlign: "center" }}>
                <p style={{ fontSize: "1.2em" }}>Now Loading <Spinner animation="border" variant="secondary" /> </p>
            </Modal.Body>
        </Modal>
    )
}

export default Loading