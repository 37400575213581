import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import { Container, Pagination, Col } from 'react-bootstrap'
import Entry from './components/Entry';
import { Blog } from './entity/Blog';
import { getAfterBlogs, getAllBlogs, getBlogs, getStartAtBlogs } from './api/firestore';
import Loading from './components/Loading';

const App = () => {
  const [blogs, setBlogs] = useState([] as Blog[])
  const pageSize = 5
  const [startBlogs, setStartBlogs] = useState([] as Blog[])
  const [isLoading, setIsLoading] = useState(true)
  const [isBeforeExist, setBeforeExist] = useState(false)
  const [isAfterExist, setAfterExist] = useState(false)
  const [yearMonths, setYearMonths] = useState([] as string[])
  const [categories, setCategories] = useState([] as string[])
  const [filterYearMonth, setFilterYearMonth] = useState(null as string | null)
  const [filterCategory, setFilterCategory] = useState(null as string | null)

  useEffect(() => {
    getAllBlogs().then(blogs => setYearMonths(Array.from(new Set(blogs.map(blog => blog.yearMonth))).sort().reverse()))
  }, [])

  useEffect(() => {
    getBlogs(null, filterYearMonth, null).then(blogs => {
      setCategories(Array.from(new Set(blogs.map(blog => blog.category).flat())))
    })
  }, [filterYearMonth])

  useEffect(() => {
    setIsLoading(true)
    getBlogs(pageSize, filterYearMonth, filterCategory).then(blogs => {
      setBlogs(blogs)
      setStartBlogs([blogs[0]])
    }).finally(() => setIsLoading(false))
  }, [filterYearMonth, filterCategory])

  useEffect(() => {
    if (blogs.length !== 0) {
      setBeforeExist(startBlogs.length > 1)
      getAfterBlogs(
        pageSize,
        blogs[blogs.length - 1],
        filterYearMonth,
        filterCategory
      ).then(blogs => setAfterExist(blogs.length !== 0))
    } else {
      setBeforeExist(false)
      setAfterExist(false)
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [blogs, startBlogs, filterYearMonth, filterCategory])

  const getNextPage = () => {
    setIsLoading(true)
    getAfterBlogs(pageSize, blogs[blogs.length - 1], filterYearMonth, filterCategory).then(afterBlogs => {
      setBlogs(afterBlogs)
      startBlogs.push(afterBlogs[0])
      setStartBlogs(Array.from(startBlogs))
    }).finally(() => setIsLoading(false))
  }

  const getPrevPage = () => {
    setIsLoading(true)
    getStartAtBlogs(pageSize, startBlogs[startBlogs.length - 2], filterYearMonth, filterCategory).then(
      beforeBlogs => {
        setBlogs(beforeBlogs)
        setStartBlogs(Array.from(startBlogs.slice(0, -1)))
      }
    ).finally(() => setIsLoading(false))
  }

  return (
    <div id="App">
      <Header
        yearMonths={yearMonths}
        setFilterYearMonth={setFilterYearMonth}
        categories={categories}
        setFilterCategory={setFilterCategory}
      />
      <Container>
        <Loading show={isLoading} />
        {blogs.map(blog => <Entry key={blog.id} blog={blog} />)}
        <Pagination>
          <Col xs={1}>
            {
              isBeforeExist ?
                <Pagination.Prev onClick={() => getPrevPage()} /> :
                null
            }
          </Col>
          <Col xs={{ span: 1, offset: 9 }}>
            {
              isAfterExist ?
                <Pagination.Next onClick={() => getNextPage()} /> :
                null
            }
          </Col>
        </Pagination>
      </Container>
    </div >
  )
}

export default App;
